// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicPermissionRuleGroup_root__ZytHZ {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  height: auto;\n  max-width: 100%;\n  overflow: hidden;\n  position: relative;\n  min-width: 0;\n  border-radius: 8px;\n  border: 1px solid var(--token-hoA5qaM-91G);\n}\n.PlasmicPermissionRuleGroup_permissionRule__lhnJ2__WTfyQ.__wab_instance {\n  position: relative;\n}\n.PlasmicPermissionRuleGroup_permissionRule__nvdAj__KzPis.__wab_instance {\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_end_user_management/PlasmicPermissionRuleGroup.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,0CAA0C;AAC5C;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: flex-start;\n  width: 100%;\n  height: auto;\n  max-width: 100%;\n  overflow: hidden;\n  position: relative;\n  min-width: 0;\n  border-radius: 8px;\n  border: 1px solid var(--token-hoA5qaM-91G);\n}\n.permissionRule__lhnJ2:global(.__wab_instance) {\n  position: relative;\n}\n.permissionRule__nvdAj:global(.__wab_instance) {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicPermissionRuleGroup_root__ZytHZ",
	"permissionRule__lhnJ2": "PlasmicPermissionRuleGroup_permissionRule__lhnJ2__WTfyQ",
	"permissionRule__nvdAj": "PlasmicPermissionRuleGroup_permissionRule__nvdAj__KzPis"
};
export default ___CSS_LOADER_EXPORT___;
