// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicVariantPinButton_root__S\\+ugb.__wab_instance {\n  position: relative;\n}\n.PlasmicVariantPinButton_svg__fSc0K__VVSw6 {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1rem;\n  height: 1rem;\n}\n.PlasmicVariantPinButton_svgpinState_pinnedTrue__fSc0KdSe1E__Sd1TT {\n  color: var(--token-Y2CWh0ci95a);\n}\n.PlasmicVariantPinButton_svgpinState_pinnedFalse__fSc0KPSWf__4rC0e {\n  color: var(--token-Y2CWh0ci95a);\n}\n.PlasmicVariantPinButton_svgpinState_selected__fSc0KEqRw__GOUlP {\n  color: var(--token-Y2CWh0ci95a);\n}\n.PlasmicVariantPinButton_svgpinState_evaluatedTrue__fSc0KznhMp__kLZEQ {\n  color: var(--token-Y2CWh0ci95a);\n}\n.PlasmicVariantPinButton_svg__dl131__sBrCP {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-UunsGa2Y3t3);\n  width: 1em;\n  height: 1em;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_variants/PlasmicVariantPinButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,+BAA+B;AACjC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,+BAA+B;AACjC;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;EAC/B,UAAU;EACV,WAAW;AACb","sourcesContent":[".root:global(.__wab_instance) {\n  position: relative;\n}\n.svg__fSc0K {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1rem;\n  height: 1rem;\n}\n.svgpinState_pinnedTrue__fSc0KdSe1E {\n  color: var(--token-Y2CWh0ci95a);\n}\n.svgpinState_pinnedFalse__fSc0KPSWf {\n  color: var(--token-Y2CWh0ci95a);\n}\n.svgpinState_selected__fSc0KEqRw {\n  color: var(--token-Y2CWh0ci95a);\n}\n.svgpinState_evaluatedTrue__fSc0KznhMp {\n  color: var(--token-Y2CWh0ci95a);\n}\n.svg__dl131 {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-UunsGa2Y3t3);\n  width: 1em;\n  height: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicVariantPinButton_root__S+ugb",
	"svg__fSc0K": "PlasmicVariantPinButton_svg__fSc0K__VVSw6",
	"svgpinState_pinnedTrue__fSc0KdSe1E": "PlasmicVariantPinButton_svgpinState_pinnedTrue__fSc0KdSe1E__Sd1TT",
	"svgpinState_pinnedFalse__fSc0KPSWf": "PlasmicVariantPinButton_svgpinState_pinnedFalse__fSc0KPSWf__4rC0e",
	"svgpinState_selected__fSc0KEqRw": "PlasmicVariantPinButton_svgpinState_selected__fSc0KEqRw__GOUlP",
	"svgpinState_evaluatedTrue__fSc0KznhMp": "PlasmicVariantPinButton_svgpinState_evaluatedTrue__fSc0KznhMp__kLZEQ",
	"svg__dl131": "PlasmicVariantPinButton_svg__dl131__sBrCP"
};
export default ___CSS_LOADER_EXPORT___;
