// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListStyleSection_selectIcon__RQa1l {\n  color: #999;\n  display: inline-block;\n  font-size: 0.8em;\n  text-align: center;\n  width: 16px;\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/sidebar-tabs/ListStyleSection.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,WAAA;AACF","sourcesContent":[".selectIcon {\n  color: #999;\n  display: inline-block;\n  font-size: 0.8em;\n  text-align: center;\n  width: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectIcon": "ListStyleSection_selectIcon__RQa1l"
};
export default ___CSS_LOADER_EXPORT___;
