// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InvalidationEditor_rightTrashIcon--vertical__Y0HnT {\n  position: absolute;\n  right: 5px;\n  top: 75%;\n  -webkit-transform: translateY(-50%);\n          transform: translateY(-50%);\n}\n\n.InvalidationEditor_rightTrashIcon--horizontal__A7qbm {\n  position: absolute;\n  right: 5px;\n  top: 50%;\n  -webkit-transform: translateY(-50%);\n          transform: translateY(-50%);\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/sidebar-tabs/ComponentProps/InvalidationEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,mCAAA;UAAA,2BAAA;AACF;;AACA;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EACA,mCAAA;UAAA,2BAAA;AAEF","sourcesContent":[".rightTrashIcon--vertical {\n  position: absolute;\n  right: 5px;\n  top: 75%;\n  transform: translateY(-50%);\n}\n.rightTrashIcon--horizontal {\n  position: absolute;\n  right: 5px;\n  top: 50%;\n  transform: translateY(-50%);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rightTrashIcon--vertical": "InvalidationEditor_rightTrashIcon--vertical__Y0HnT",
	"rightTrashIcon--horizontal": "InvalidationEditor_rightTrashIcon--horizontal__A7qbm"
};
export default ___CSS_LOADER_EXPORT___;
