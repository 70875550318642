// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateStringEditor_DateStringEditor__RKeaM {\n  width: 100%;\n  display: flex;\n  background-color: var(--token-O4S7RMTqZ3) !important;\n  border-radius: 6px !important;\n  border: none !important;\n}\n\n.DateStringEditor_DateStringEditor__RKeaM input {\n  font-size: 12px !important;\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/sidebar-tabs/ComponentProps/DateStringEditor.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,oDAAA;EACA,6BAAA;EACA,uBAAA;AACF;;AAEA;EACE,0BAAA;AACF","sourcesContent":[".DateStringEditor {\n  width: 100%;\n  display: flex;\n  background-color: var(--token-O4S7RMTqZ3) !important;\n  border-radius: 6px !important;\n  border: none !important;\n}\n\n.DateStringEditor input {\n  font-size: 12px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DateStringEditor": "DateStringEditor_DateStringEditor__RKeaM"
};
export default ___CSS_LOADER_EXPORT___;
