// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicDropdownOverlay_root__\\+ikzy {\n  box-shadow: 0px 8px 32px -8px var(--token-XeFw4MGauXBT),\n    0px 8px 20px -16px var(--token-JrjdlBU-a5Ju);\n  display: inline-flex;\n  width: auto;\n  height: auto;\n  position: relative;\n  flex-direction: column;\n  background: rgb(255, 255, 255);\n  border-radius: 2px;\n  padding: 4px 0px;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_design_system/PlasmicDropdownOverlay.module.css"],"names":[],"mappings":"AAAA;EACE;gDAC8C;EAC9C,oBAAoB;EACpB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".root {\n  box-shadow: 0px 8px 32px -8px var(--token-XeFw4MGauXBT),\n    0px 8px 20px -16px var(--token-JrjdlBU-a5Ju);\n  display: inline-flex;\n  width: auto;\n  height: auto;\n  position: relative;\n  flex-direction: column;\n  background: rgb(255, 255, 255);\n  border-radius: 2px;\n  padding: 4px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicDropdownOverlay_root__+ikzy"
};
export default ___CSS_LOADER_EXPORT___;
