// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicListSectionSeparator_root__daAWO {\n  display: flex;\n  width: 100%;\n  height: 1px;\n  position: relative;\n  min-width: 0;\n  border-bottom: 1px solid var(--token-hoA5qaM-91G);\n}\n.PlasmicListSectionSeparator_rootcenter__79rjS {\n  border-bottom-style: none;\n}\n.PlasmicListSectionSeparator_freeBox__aF70k {\n  display: flex;\n  position: relative;\n  flex-direction: row;\n  width: 100%;\n  height: 0px;\n  margin-right: var(--token-NCc1lDy9R);\n  margin-left: var(--token-NCc1lDy9R);\n  align-self: center;\n  min-width: 0;\n  border-bottom: 1px solid var(--token-hoA5qaM-91G);\n}\n.PlasmicListSectionSeparator_freeBoxcenter__Qrm3s {\n  display: flex;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_design_system/PlasmicListSectionSeparator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,iDAAiD;AACnD;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,oCAAoC;EACpC,mCAAmC;EACnC,kBAAkB;EAClB,YAAY;EACZ,iDAAiD;AACnD;AACA;EACE,aAAa;AACf","sourcesContent":[".root {\n  display: flex;\n  width: 100%;\n  height: 1px;\n  position: relative;\n  min-width: 0;\n  border-bottom: 1px solid var(--token-hoA5qaM-91G);\n}\n.rootcenter {\n  border-bottom-style: none;\n}\n.freeBox {\n  display: flex;\n  position: relative;\n  flex-direction: row;\n  width: 100%;\n  height: 0px;\n  margin-right: var(--token-NCc1lDy9R);\n  margin-left: var(--token-NCc1lDy9R);\n  align-self: center;\n  min-width: 0;\n  border-bottom: 1px solid var(--token-hoA5qaM-91G);\n}\n.freeBoxcenter {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicListSectionSeparator_root__daAWO",
	"rootcenter": "PlasmicListSectionSeparator_rootcenter__79rjS",
	"freeBox": "PlasmicListSectionSeparator_freeBox__aF70k",
	"freeBoxcenter": "PlasmicListSectionSeparator_freeBoxcenter__Qrm3s"
};
export default ___CSS_LOADER_EXPORT___;
