// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicSearchbox_root__0y0C7.__wab_instance {\n  position: relative;\n}\n.PlasmicSearchbox_svg__unKzu__mdOY9 {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1rem;\n  height: 1rem;\n}\n.PlasmicSearchbox_svghasValue__unKzuuzQ8L__O4L2r {\n  left: auto;\n  top: auto;\n}\n.PlasmicSearchbox_svgmedium__unKzuKYp1__gu2FB {\n  width: 1.25rem;\n  height: 1.25rem;\n}\n.PlasmicSearchbox_svg___2K8R__w27nr {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1rem;\n  height: 1rem;\n}\n.PlasmicSearchbox_svghasValue___2K8RuzQ8L__YTU7- {\n  left: auto;\n  top: auto;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit/PlasmicSearchbox.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,UAAU;EACV,SAAS;AACX;AACA;EACE,cAAc;EACd,eAAe;AACjB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,UAAU;EACV,SAAS;AACX","sourcesContent":[".root:global(.__wab_instance) {\n  position: relative;\n}\n.svg__unKzu {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1rem;\n  height: 1rem;\n}\n.svghasValue__unKzuuzQ8L {\n  left: auto;\n  top: auto;\n}\n.svgmedium__unKzuKYp1 {\n  width: 1.25rem;\n  height: 1.25rem;\n}\n.svg___2K8R {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1rem;\n  height: 1rem;\n}\n.svghasValue___2K8RuzQ8L {\n  left: auto;\n  top: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicSearchbox_root__0y0C7",
	"svg__unKzu": "PlasmicSearchbox_svg__unKzu__mdOY9",
	"svghasValue__unKzuuzQ8L": "PlasmicSearchbox_svghasValue__unKzuuzQ8L__O4L2r",
	"svgmedium__unKzuKYp1": "PlasmicSearchbox_svgmedium__unKzuKYp1__gu2FB",
	"svg___2K8R": "PlasmicSearchbox_svg___2K8R__w27nr",
	"svghasValue___2K8RuzQ8L": "PlasmicSearchbox_svghasValue___2K8RuzQ8L__YTU7-"
};
export default ___CSS_LOADER_EXPORT___;
