// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopModal_wrapper__XPRoo {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.TopModal_wrapper__XPRoo .ant-modal-body {\n  padding: 0;\n}\n\n.TopModal_wrapper__XPRoo .ant-modal {\n  width: auto !important;\n  margin: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/components/studio/TopModal.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,sBAAsB;EACtB,SAAS;AACX","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-start;\n}\n\n.wrapper :global(.ant-modal-body) {\n  padding: 0;\n}\n\n.wrapper :global(.ant-modal) {\n  width: auto !important;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "TopModal_wrapper__XPRoo"
};
export default ___CSS_LOADER_EXPORT___;
