// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BareModal_wraper__QRgfu {\n}\n\n.BareModal_wrapper__g6J8L .ant-modal-body {\n  padding: 0;\n}\n\n.BareModal_wrapper__g6J8L .ant-modal-content {\n  background: none;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/components/studio/BareModal.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".wraper {\n}\n\n.wrapper :global(.ant-modal-body) {\n  padding: 0;\n}\n\n.wrapper :global(.ant-modal-content) {\n  background: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wraper": "BareModal_wraper__QRgfu",
	"wrapper": "BareModal_wrapper__g6J8L"
};
export default ___CSS_LOADER_EXPORT___;
