// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicPromoBanner_root__5mduj {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n  height: auto;\n  justify-content: flex-start;\n  align-items: center;\n  background: #01020e;\n  min-width: 0;\n}\n.PlasmicPromoBanner_text__k96R9 {\n  position: relative;\n  width: auto;\n  height: auto;\n  max-width: 100%;\n  line-height: 2em;\n  color: var(--token-olzpv9Zfv0);\n  font-size: 14px;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_alert_banner/PlasmicPromoBanner.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,8BAA8B;EAC9B,eAAe;AACjB","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  width: 100%;\n  height: auto;\n  justify-content: flex-start;\n  align-items: center;\n  background: #01020e;\n  min-width: 0;\n}\n.text {\n  position: relative;\n  width: auto;\n  height: auto;\n  max-width: 100%;\n  line-height: 2em;\n  color: var(--token-olzpv9Zfv0);\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicPromoBanner_root__5mduj",
	"text": "PlasmicPromoBanner_text__k96R9"
};
export default ___CSS_LOADER_EXPORT___;
