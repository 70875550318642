// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PP__plasmickit_init_token_root_reset__Zp64H {\n  font-family: \"Inter\", sans-serif;\n  font-size: 11px;\n  font-weight: 400;\n  font-style: normal;\n  color: #535353;\n  text-align: left;\n  text-transform: none;\n  line-height: 16px;\n  letter-spacing: normal;\n  white-space: pre-wrap;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/PP__plasmickit_init_token.module.css"],"names":[],"mappings":"AACA;EACE,gCAAgC;EAChC,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,oBAAoB;EACpB,iBAAiB;EACjB,sBAAsB;EACtB,qBAAqB;AACvB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C400&display=swap\");\n.root_reset {\n  font-family: \"Inter\", sans-serif;\n  font-size: 11px;\n  font-weight: 400;\n  font-style: normal;\n  color: #535353;\n  text-align: left;\n  text-transform: none;\n  line-height: 16px;\n  letter-spacing: normal;\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root_reset": "PP__plasmickit_init_token_root_reset__Zp64H"
};
export default ___CSS_LOADER_EXPORT___;
