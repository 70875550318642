// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicIndicator_root__vr9rN {\n  display: flex;\n  position: relative;\n  padding: 4px;\n}\n.PlasmicIndicator_dot__\\+skJ5 {\n  display: flex;\n  width: 4px;\n  height: 4px;\n  background: var(--token-D666zt2IZPL);\n  box-shadow: 0px 0px 0px 1px var(--token-iR8SeEwQZ);\n  flex-shrink: 0;\n  border-radius: 50%;\n}\n.PlasmicIndicator_dotcolor_red__ku4id {\n  background: var(--token-iDpByWoW0eF);\n}\n.PlasmicIndicator_dotcolor_green__njslm {\n  background: var(--token-GEBK7U4Am62W);\n}\n.PlasmicIndicator_dotcolor_gray__GWJpF {\n  background: var(--token-fVn5vRhXJxQ);\n}\n.PlasmicIndicator_dotcolor_purple__hiXW0 {\n  background: var(--token-Izr3_jwKAp1);\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_design_system/PlasmicIndicator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,aAAa;EACb,UAAU;EACV,WAAW;EACX,oCAAoC;EACpC,kDAAkD;EAClD,cAAc;EACd,kBAAkB;AACpB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,qCAAqC;AACvC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC","sourcesContent":[".root {\n  display: flex;\n  position: relative;\n  padding: 4px;\n}\n.dot {\n  display: flex;\n  width: 4px;\n  height: 4px;\n  background: var(--token-D666zt2IZPL);\n  box-shadow: 0px 0px 0px 1px var(--token-iR8SeEwQZ);\n  flex-shrink: 0;\n  border-radius: 50%;\n}\n.dotcolor_red {\n  background: var(--token-iDpByWoW0eF);\n}\n.dotcolor_green {\n  background: var(--token-GEBK7U4Am62W);\n}\n.dotcolor_gray {\n  background: var(--token-fVn5vRhXJxQ);\n}\n.dotcolor_purple {\n  background: var(--token-Izr3_jwKAp1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicIndicator_root__vr9rN",
	"dot": "PlasmicIndicator_dot__+skJ5",
	"dotcolor_red": "PlasmicIndicator_dotcolor_red__ku4id",
	"dotcolor_green": "PlasmicIndicator_dotcolor_green__njslm",
	"dotcolor_gray": "PlasmicIndicator_dotcolor_gray__GWJpF",
	"dotcolor_purple": "PlasmicIndicator_dotcolor_purple__hiXW0"
};
export default ___CSS_LOADER_EXPORT___;
