// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicNavTeamSection_root__shTm2 {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  align-items: stretch;\n  justify-content: flex-start;\n  overflow: hidden;\n  border-radius: 6px;\n}\n.PlasmicNavTeamSection_rootselected__V879T {\n  background: var(--token-p-rw5DRJTx);\n}\n.PlasmicNavTeamSection_freeBox__h\\+-QV {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  align-items: stretch;\n  justify-content: flex-start;\n  padding-left: 0px;\n}\n.PlasmicNavTeamSection_svg___1NhRc__XUdK1 {\n  display: flex;\n  position: relative;\n  width: 24px;\n  height: 24px;\n}\n.PlasmicNavTeamSection_svg__vpdso__Omqn9 {\n  display: flex;\n  position: relative;\n  width: 24px;\n  height: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_dashboard/PlasmicNavTeamSection.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,oBAAoB;EACpB,2BAA2B;EAC3B,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,mCAAmC;AACrC;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,sBAAsB;EACtB,oBAAoB;EACpB,2BAA2B;EAC3B,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd","sourcesContent":[".root {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  align-items: stretch;\n  justify-content: flex-start;\n  overflow: hidden;\n  border-radius: 6px;\n}\n.rootselected {\n  background: var(--token-p-rw5DRJTx);\n}\n.freeBox {\n  display: flex;\n  position: relative;\n  flex-direction: column;\n  align-items: stretch;\n  justify-content: flex-start;\n  padding-left: 0px;\n}\n.svg___1NhRc {\n  display: flex;\n  position: relative;\n  width: 24px;\n  height: 24px;\n}\n.svg__vpdso {\n  display: flex;\n  position: relative;\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicNavTeamSection_root__shTm2",
	"rootselected": "PlasmicNavTeamSection_rootselected__V879T",
	"freeBox": "PlasmicNavTeamSection_freeBox__h+-QV",
	"svg___1NhRc": "PlasmicNavTeamSection_svg___1NhRc__XUdK1",
	"svg__vpdso": "PlasmicNavTeamSection_svg__vpdso__Omqn9"
};
export default ___CSS_LOADER_EXPORT___;
