// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicFilterButton_root__3cgPB.__wab_instance {\n  max-width: 100%;\n  position: relative;\n}\n.PlasmicFilterButton_svg__h6NCv__0y4vx {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-iR8SeEwQZ);\n  height: 1em;\n}\n.PlasmicFilterButton_svg__wHz3V__3ILxK {\n  position: relative;\n  object-fit: cover;\n  max-width: 100%;\n  width: 24px;\n  height: 24px;\n}\n.PlasmicFilterButton_svgisActive__wHz3Vpuhxi__2BZbu {\n  color: var(--token-hIvqQBCxyWwX);\n}\n.PlasmicFilterButton_svg__ydByj__3HtZo {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  height: 1em;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_left_pane/PlasmicFilterButton.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,6BAA6B;EAC7B,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,WAAW;EACX,YAAY;AACd;AACA;EACE,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;AACb","sourcesContent":[".root:global(.__wab_instance) {\n  max-width: 100%;\n  position: relative;\n}\n.svg__h6NCv {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-iR8SeEwQZ);\n  height: 1em;\n}\n.svg__wHz3V {\n  position: relative;\n  object-fit: cover;\n  max-width: 100%;\n  width: 24px;\n  height: 24px;\n}\n.svgisActive__wHz3Vpuhxi {\n  color: var(--token-hIvqQBCxyWwX);\n}\n.svg__ydByj {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  height: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicFilterButton_root__3cgPB",
	"svg__h6NCv": "PlasmicFilterButton_svg__h6NCv__0y4vx",
	"svg__wHz3V": "PlasmicFilterButton_svg__wHz3V__3ILxK",
	"svgisActive__wHz3Vpuhxi": "PlasmicFilterButton_svgisActive__wHz3Vpuhxi__2BZbu",
	"svg__ydByj": "PlasmicFilterButton_svg__ydByj__3HtZo"
};
export default ___CSS_LOADER_EXPORT___;
