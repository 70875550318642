// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicShareButton_root__U-Ux7.__wab_instance {\n  position: relative;\n}\n.PlasmicShareButton_svg__lh32G__a5v-u {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 16px;\n  height: 16px;\n}\n.PlasmicShareButton_svg__rUr3X__deWip {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 16px;\n  height: 1em;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_dashboard/PlasmicShareButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,WAAW;AACb","sourcesContent":[".root:global(.__wab_instance) {\n  position: relative;\n}\n.svg__lh32G {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 16px;\n  height: 16px;\n}\n.svg__rUr3X {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 16px;\n  height: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicShareButton_root__U-Ux7",
	"svg__lh32G": "PlasmicShareButton_svg__lh32G__a5v-u",
	"svg__rUr3X": "PlasmicShareButton_svg__rUr3X__deWip"
};
export default ___CSS_LOADER_EXPORT___;
