// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicExpandButton_root__gS8LM.__wab_instance {\n  position: relative;\n}\n.PlasmicExpandButton_svg__y7QMe__Fya6H {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-UunsGa2Y3t3);\n  width: 24px;\n  height: 24px;\n}\n.PlasmicExpandButton_svgisExpanded__y7QMe5Dg9P__b6qde {\n  color: var(--token-UunsGa2Y3t3);\n}\n.PlasmicExpandButton_svg__wgw3N__YY2Cb {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-UunsGa2Y3t3);\n  height: 1em;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_design_system/PlasmicExpandButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;EAC/B,WAAW;EACX,YAAY;AACd;AACA;EACE,+BAA+B;AACjC;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;EAC/B,WAAW;AACb","sourcesContent":[".root:global(.__wab_instance) {\n  position: relative;\n}\n.svg__y7QMe {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-UunsGa2Y3t3);\n  width: 24px;\n  height: 24px;\n}\n.svgisExpanded__y7QMe5Dg9P {\n  color: var(--token-UunsGa2Y3t3);\n}\n.svg__wgw3N {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-UunsGa2Y3t3);\n  height: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicExpandButton_root__gS8LM",
	"svg__y7QMe": "PlasmicExpandButton_svg__y7QMe__Fya6H",
	"svgisExpanded__y7QMe5Dg9P": "PlasmicExpandButton_svgisExpanded__y7QMe5Dg9P__b6qde",
	"svg__wgw3N": "PlasmicExpandButton_svg__wgw3N__YY2Cb"
};
export default ___CSS_LOADER_EXPORT___;
