// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditableLabel_fullWidthLabelEditing__rPv9K {\n  overflow: visible;\n  overflow: initial;\n  text-overflow: initial;\n}\n\n.EditableLabel_inputBox__7zBKj {\n  width: 100%;\n  border: 2px solid rgb(0, 145, 255);\n  padding: 2px;\n  outline: none;\n  background: white;\n  line-height: 1.2em;\n  border-radius: 2px;\n  display: inline-flex;\n  align-items: center;\n  overflow: visible !important;\n  overflow: initial !important;\n  color: #222;\n}\n\nspan.EditableLabel_inputBox__7zBKj[placeholder]:empty:focus::before {\n  content: attr(placeholder);\n  line-height: 1.2em;\n  display: inline-flex;\n  align-items: center;\n  color: rgba(0, 0, 0, 0.2);\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/widgets/EditableLabel/EditableLabel.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;EAAA,iBAAA;EACA,sBAAA;AADF;;AAIA;EACE,WAAA;EACA,kCAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,4BAAA;EAAA,4BAAA;EACA,WAAA;AADF;;AAIA;EACE,0BAAA;EACA,kBAAA;EACA,oBAAA;EACA,mBAAA;EACA,yBAAA;AADF","sourcesContent":["@import \"src/wab/styles/tokens\";\n\n.fullWidthLabelEditing {\n  overflow: unset;\n  text-overflow: unset;\n}\n\n.inputBox {\n  width: 100%;\n  border: 2px solid $blue9;\n  padding: 2px;\n  outline: none;\n  background: white;\n  line-height: 1.2em;\n  border-radius: 2px;\n  display: inline-flex;\n  align-items: center;\n  overflow: unset !important;\n  color: #222;\n}\n\nspan.inputBox[placeholder]:empty:focus::before {\n  content: attr(placeholder);\n  line-height: 1.2em;\n  display: inline-flex;\n  align-items: center;\n  color: rgba(0, 0, 0, 0.2);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullWidthLabelEditing": "EditableLabel_fullWidthLabelEditing__rPv9K",
	"inputBox": "EditableLabel_inputBox__7zBKj"
};
export default ___CSS_LOADER_EXPORT___;
