// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicMenuButton_root__Pwdxj.__wab_instance {\n  position: relative;\n}\n.PlasmicMenuButton_svg__jeWc1__pzLLd {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1rem;\n  height: 1rem;\n}\n.PlasmicMenuButton_root__Pwdxj:hover .PlasmicMenuButton_svg__jeWc1__pzLLd {\n  color: var(--token-PHHWT_HMuS1);\n}\n.PlasmicMenuButton_svg___5FElZ__Y8leX {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-UunsGa2Y3t3);\n  height: 1em;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_design_system/PlasmicMenuButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,+BAA+B;AACjC;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;EAC/B,WAAW;AACb","sourcesContent":[".root:global(.__wab_instance) {\n  position: relative;\n}\n.svg__jeWc1 {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1rem;\n  height: 1rem;\n}\n.root:hover .svg__jeWc1 {\n  color: var(--token-PHHWT_HMuS1);\n}\n.svg___5FElZ {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  color: var(--token-UunsGa2Y3t3);\n  height: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicMenuButton_root__Pwdxj",
	"svg__jeWc1": "PlasmicMenuButton_svg__jeWc1__pzLLd",
	"svg___5FElZ": "PlasmicMenuButton_svg___5FElZ__Y8leX"
};
export default ___CSS_LOADER_EXPORT___;
