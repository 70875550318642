// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PublishSpinner_spin__t9cBY {\n  height: 16px;\n  width: 16px;\n}\n.PublishSpinner_spin__t9cBY .ant-spin-dot {\n  font-size: 9px;\n}\n.PublishSpinner_spin__t9cBY .ant-spin-dot-item {\n  height: 4px;\n  width: 4px;\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/widgets/PublishSpinner.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,WAAA;EACA,UAAA;AAAJ","sourcesContent":[".spin {\n  height: 16px;\n  width: 16px;\n\n  :global(.ant-spin-dot) {\n    font-size: 9px;\n  }\n\n  :global(.ant-spin-dot-item) {\n    height: 4px;\n    width: 4px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spin": "PublishSpinner_spin__t9cBY"
};
export default ___CSS_LOADER_EXPORT___;
