// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicColorTokenControl_root__B6DbR {\n  display: flex;\n  width: 100%;\n  height: auto;\n  position: relative;\n  min-width: 0;\n  padding: 0px 1px;\n}\n.PlasmicColorTokenControl_listItem__-7JV4.__wab_instance {\n  position: relative;\n}\n.PlasmicColorTokenControl_svg__oaVe8__c-3ZW {\n  object-fit: cover;\n  width: 24px;\n  height: 24px;\n}\n.PlasmicColorTokenControl_svg__gyTcw {\n  position: relative;\n  object-fit: cover;\n  width: 24px;\n  height: 24px;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_left_pane/PlasmicColorTokenControl.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,WAAW;EACX,YAAY;AACd","sourcesContent":[".root {\n  display: flex;\n  width: 100%;\n  height: auto;\n  position: relative;\n  min-width: 0;\n  padding: 0px 1px;\n}\n.listItem:global(.__wab_instance) {\n  position: relative;\n}\n.svg__oaVe8 {\n  object-fit: cover;\n  width: 24px;\n  height: 24px;\n}\n.svg {\n  position: relative;\n  object-fit: cover;\n  width: 24px;\n  height: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicColorTokenControl_root__B6DbR",
	"listItem": "PlasmicColorTokenControl_listItem__-7JV4",
	"svg__oaVe8": "PlasmicColorTokenControl_svg__oaVe8__c-3ZW",
	"svg": "PlasmicColorTokenControl_svg__gyTcw"
};
export default ___CSS_LOADER_EXPORT___;
