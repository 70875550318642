// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicViewAsButton_root__brbpB.__wab_instance {\n  max-width: 100%;\n  position: relative;\n}\n.PlasmicViewAsButton_svg___3Qolh__okkIO {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1em;\n  height: 1em;\n}\n.PlasmicViewAsButton_svg___96QKk__ri7V2 {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1em;\n  height: 1em;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_top_bar/PlasmicViewAsButton.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,WAAW;AACb;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,iBAAiB;EACjB,UAAU;EACV,WAAW;AACb","sourcesContent":[".root:global(.__wab_instance) {\n  max-width: 100%;\n  position: relative;\n}\n.svg___3Qolh {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1em;\n  height: 1em;\n}\n.svg___96QKk {\n  display: flex;\n  position: relative;\n  object-fit: cover;\n  width: 1em;\n  height: 1em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicViewAsButton_root__brbpB",
	"svg___3Qolh": "PlasmicViewAsButton_svg___3Qolh__okkIO",
	"svg___96QKk": "PlasmicViewAsButton_svg___96QKk__ri7V2"
};
export default ___CSS_LOADER_EXPORT___;
