// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConnectOAuth_Icon__iZPBq {\n  height: 1.3em;\n  margin-right: 5px;\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/auth/ConnectOAuth.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;AACF","sourcesContent":[".Icon {\n  height: 1.3em;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Icon": "ConnectOAuth_Icon__iZPBq"
};
export default ___CSS_LOADER_EXPORT___;
