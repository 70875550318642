// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QueryBuilder_plasmicQueryBuilderScope__L8Nzx {\n  width: 100%;\n  margin: 10px 0;\n  padding: 14px 0 20px 0;\n  border-top: 1px solid #eee;\n  border-bottom: 1px solid #eee;\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/QueryBuilder/QueryBuilder.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,cAAA;EACA,sBAAA;EACA,0BAAA;EACA,6BAAA;AACF","sourcesContent":[".plasmicQueryBuilderScope {\n  width: 100%;\n  margin: 10px 0;\n  padding: 14px 0 20px 0;\n  border-top: 1px solid #eee;\n  border-bottom: 1px solid #eee;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"plasmicQueryBuilderScope": "QueryBuilder_plasmicQueryBuilderScope__L8Nzx"
};
export default ___CSS_LOADER_EXPORT___;
