// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PlasmicNavSeparator_root__L-dXW {\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n  justify-content: flex-start;\n  padding-left: 8px;\n  padding-right: 8px;\n  position: relative;\n}\n.PlasmicNavSeparator_roothideStarters__NzJqP {\n  display: none;\n}\n.PlasmicNavSeparator_rootnoPadding__NuK3V {\n  padding: 0px;\n}\n.PlasmicNavSeparator_freeBox__G47wB {\n  display: block;\n  width: 100%;\n  height: 1px;\n  background: var(--token-hoA5qaM-91G);\n  min-width: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/plasmic/plasmic_kit_dashboard/PlasmicNavSeparator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAoB;EACpB,2BAA2B;EAC3B,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;EACE,cAAc;EACd,WAAW;EACX,WAAW;EACX,oCAAoC;EACpC,YAAY;AACd","sourcesContent":[".root {\n  display: flex;\n  flex-direction: row;\n  align-items: stretch;\n  justify-content: flex-start;\n  padding-left: 8px;\n  padding-right: 8px;\n  position: relative;\n}\n.roothideStarters {\n  display: none;\n}\n.rootnoPadding {\n  padding: 0px;\n}\n.freeBox {\n  display: block;\n  width: 100%;\n  height: 1px;\n  background: var(--token-hoA5qaM-91G);\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "PlasmicNavSeparator_root__L-dXW",
	"roothideStarters": "PlasmicNavSeparator_roothideStarters__NzJqP",
	"rootnoPadding": "PlasmicNavSeparator_rootnoPadding__NuK3V",
	"freeBox": "PlasmicNavSeparator_freeBox__G47wB"
};
export default ___CSS_LOADER_EXPORT___;
