// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BetterAutoComplete_container__hLcaZ .ant-dropdown-menu-root {\n  max-height: 30vh;\n}\n\n.BetterAutoComplete_item__active__UzAQH {\n  background-color: #f5f5f5;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/commons/components/inputs/BetterAutoComplete.module.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".container :global(.ant-dropdown-menu-root) {\n  max-height: 30vh;\n}\n\n.item__active {\n  background-color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "BetterAutoComplete_container__hLcaZ",
	"item__active": "BetterAutoComplete_item__active__UzAQH"
};
export default ___CSS_LOADER_EXPORT___;
