// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SiteDiffs_item__OSxsN {\n  margin: 8px 0;\n}\n\n.SiteDiffs_headerItem__VJ2LN {\n  margin: 8px 0;\n  font-weight: 600;\n}\n", "",{"version":3,"sources":["webpack://./src/wab/client/components/modals/SiteDiffs.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".item {\n  margin: 8px 0;\n}\n\n.headerItem {\n  margin: 8px 0;\n  font-weight: 600;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "SiteDiffs_item__OSxsN",
	"headerItem": "SiteDiffs_headerItem__VJ2LN"
};
export default ___CSS_LOADER_EXPORT___;
