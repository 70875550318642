// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomBehaviorsSection_root__VD-I9 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 4px;\n}\n.CustomBehaviorsSection_root__VD-I9 a {\n  color: #1b1b18;\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/sidebar-tabs/CustomBehaviorsSection.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,QAAA;AACF;AAAE;EACE,cAAA;AAEJ","sourcesContent":[".root {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  gap: 4px;\n  a {\n    color: #1b1b18;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "CustomBehaviorsSection_root__VD-I9"
};
export default ___CSS_LOADER_EXPORT___;
