// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Shortcuts_scrollableShortcuts__-mMPI {\n  max-height: 70vh;\n  overflow: auto;\n  padding: 20px 25px;\n}\n.Shortcuts_scrollableShortcuts__-mMPI .Shortcuts_searchInput__zMQkA {\n  margin-bottom: 20px;\n}\n\n.Shortcuts_shortcutRow__ntjC5:not(:last-child) {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.07);\n}", "",{"version":3,"sources":["webpack://./src/wab/client/components/studio/Shortcuts.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ;;AAGA;EACE,4CAAA;AAAF","sourcesContent":[".scrollableShortcuts {\n  max-height: 70vh;\n  overflow: auto;\n  padding: 20px 25px;\n\n  .searchInput {\n    margin-bottom: 20px;\n  }\n}\n\n.shortcutRow:not(:last-child) {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.07);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollableShortcuts": "Shortcuts_scrollableShortcuts__-mMPI",
	"searchInput": "Shortcuts_searchInput__zMQkA",
	"shortcutRow": "Shortcuts_shortcutRow__ntjC5"
};
export default ___CSS_LOADER_EXPORT___;
